import { User } from 'firebase/auth';
import { useContext, useCallback, ReactNode, useState, useEffect, useMemo } from 'react';
import { ClusterUser } from '../../models/ClusterUser';
import { useFetch } from '../../hooks/useFetch';
import { WhoData } from '../../models/WhoData';
import {
  firebaseLoginWithCustomToken,
  firebaseLogin,
  firebaseLogout,
  getFirebaseToken,
  firebaseLoginWithOAuth,
  auth,
} from '../../utils/firebase';
import { AuthContext, IAuthContext } from './authContext';
import { useToast } from '@televet/kibble-ui/build/components/Toast';
import * as Sentry from '@sentry/nextjs';
import { onAuthStateChanged } from 'firebase/auth';
import { Pathnames } from '../../models/Pathnames';
import { LocalStorageKeys } from '../../models/LocalStorageKeys';

export interface IAuthState {
  user?: User;
  isInitialized: boolean;
  isAuthenticated: boolean;
}

export const useAuth = (): IAuthContext => useContext<IAuthContext>(AuthContext);

const AuthProvider = ({ children, value }: { children: ReactNode; value?: IAuthContext }): JSX.Element => {
  const toast = useToast();
  const [customToken, setCustomToken] = useState<string>('');
  const [clusterUsers, setClusterUsers] = useState<ClusterUser[]>([]);
  const [currentClusterUser, setCurrentClusterUser] = useState<ClusterUser | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  // making this lazy prevents unnecessary duplicate requests for InformPartnerUsers
  const {
    data: userData,
    fetch: refetchUserData,
    errors: userErrors,
  } = useFetch<WhoData>({
    url: '/api/who',
    lazy: true,
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user?.uid) {
        // don't refetch if we're on the logout page, because the user hasn't been reset yet
        // don't refetch unless current user is email/password user and we're not on OIDC login page

        const isPasswordUser = user?.providerData?.[0]?.providerId === 'password';
        if (
          isPasswordUser &&
          window.location.pathname !== Pathnames.Logout &&
          window.location.pathname !== Pathnames.OIDC
        ) {
          refetchUserData();
        }
      }
    });
    // we only want this effect to run on initial page load, so needs an empty dep array
    // otherwise we get duplicate unnecessary who requests
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (userErrors && userErrors.status === 500) {
      setError(userErrors.message);
    }
  }, [userErrors]);

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error',
        description: error,
        status: 'error',
        position: 'top',
      });
      setError(undefined);
    }

    if (userData?.clusterUsers) {
      setClusterUsers(userData.clusterUsers);
    }

    if (userData?.clusterUsers.length === 1) {
      setCurrentClusterUser(userData.clusterUsers[0]);
    }

    if (userData?.customToken) {
      setCustomToken(userData.customToken);
    }
  }, [userData, toast, error]);

  const login = useCallback(
    async (email: string, password: string): Promise<void> => {
      try {
        setError(undefined);
        setLoading(true);
        await firebaseLogin(email, password);
        await refetchUserData();
      } finally {
        setLoading(false);
      }
    },
    [refetchUserData],
  );

  const loginWithToken = useCallback(async (token: string): Promise<void> => {
    try {
      setError(undefined);
      setLoading(true);
      await firebaseLoginWithCustomToken(token);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
      Sentry.captureException(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const loginWithOAuth = useCallback(
    async (providerId: string, informPartnerUser = false, email?: string): Promise<void> => {
      const user = await firebaseLoginWithOAuth(providerId, email);

      if (user) {
        let emailParam = '';
        if (email) {
          emailParam = `&email=${encodeURIComponent(email)}`;
        }
        await refetchUserData(undefined, `/api/who?isInformPartnerUser=${informPartnerUser}${emailParam}`);
      }
    },
    [refetchUserData],
  );

  const logout = useCallback(async (): Promise<void> => {
    try {
      setError(undefined);
      setLoading(true);
      setClusterUsers([]);
      setCurrentClusterUser(undefined);
      localStorage.removeItem(LocalStorageKeys.LastOttoSSOEmail);
      await firebaseLogout();
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
      Sentry.captureException(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const getToken = useCallback(async (): Promise<string | null> => {
    try {
      const token = await getFirebaseToken();
      if (token) {
        return token;
      }
      firebaseLogout();
      return null;
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
      firebaseLogout();
      Sentry.captureException(e);
      return null;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={
        value || {
          loading,
          error,
          customToken,
          clusterUsers,
          currentClusterUser,
          login,
          loginWithToken,
          loginWithOAuth,
          logout,
          getToken,
        }
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
